<template>
	<div class="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
		<div class="max-w-md w-full">
			<div>
				<!-- <img class="mx-auto h-12 w-auto" src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg" alt="Workflow" /> -->
				<h2 class="mt-6 text-center text-3xl font-extrabold text-accent">
					Gracias por registrarte
				</h2>
				<p class="mt-2 text-center text-sm text-gray-600">
					Completa los siguientes datos para iniciar tu recorrido.
				</p>
				<p class="mt-2 text-center text-sm">
					<span @click="signout" class="font-medium text-primary hover:text-accent cursor-pointer">
						Salir
					</span>
				</p>
			</div>

			<form class="mt-8 space-y-6" @submit.prevent="submitForm">
				<input type="hidden" name="remember" value="true" />
				<div>
					<div class="rounded-md shadow-sm -space-y-px">
						<div>
							<label for="name" class="sr-only">Nombre</label>
							<input v-model="state.model.name" type="text" id="name" autocomplete="given-name" placeholder="Nombre" :disabled="state.sending" class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-accent focus:z-10 sm:text-sm" />
						</div>
						<div>
							<label for="ap-paterno" class="sr-only">Apellido paterno</label>
							<input v-model="state.model.paterno" type="text" id="ap-paterno" autocomplete="family-name" placeholder="Apellido paterno" :disabled="state.sending" class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:accent focus:border-accent focus:z-10 sm:text-sm" />
						</div>
						<div>
							<label for="ap-materno" class="sr-only">Apellido materno</label>
							<input v-model="state.model.materno" type="text" id="ap-materno" autocomplete="off" placeholder="Apellido materno" :disabled="state.sending" class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:accent focus:border-accent focus:z-10 sm:text-sm" />
						</div>
					</div>
					<div class="text-xs text-red-500 ml-1 mt-2" v-if="v$.$error">
						<div v-if="v$.model.name.$error">{{v$.model.name.$errors[0].$message}}</div>
						<div v-if="v$.model.paterno.$error">{{v$.model.paterno.$errors[0].$message}}</div>
					</div>

					<div class="text-xs mt-3 ml-2 text-gray-600">
						<div class="flex items-center space-x-2">
							<BellIcon class="w-8 h-8 -mt-1 text-primary" />
							<div>Asegúrate de escribir tu nombre como deseas que aparezca en tu constancia de participación.</div>
						</div>
					</div>

					<div class="mt-5 rounded-md shadow-sm -space-y-px">
						<div>
							<label for="password" class="sr-only">Cédula profesional</label>
							<input v-model="state.model.cedula" type="text" id="password" placeholder="Cédula profesional" :disabled="state.sending" class="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:accent focus:border-accent focus:z-10 sm:text-sm" />
						</div>
						<div>
							<label for="institucion" class="sr-only">Institución donde realizas la residencia</label>
							<input v-model="state.model.institucion" type="text" id="institucion" placeholder="Institución donde realizas la residencia" :disabled="state.sending" class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-accent focus:border-accent focus:z-10 sm:text-sm" />
						</div>
					</div>
					<div class="text-xs text-red-500 ml-1 mt-2" v-if="v$.$error">
						<div v-if="v$.model.cedula.$error">{{v$.model.cedula.$errors[0].$message}}</div>
						<div v-if="v$.model.institucion.$error">{{v$.model.institucion.$errors[0].$message}}</div>
					</div>

					<div class="mt-5 rounded-md shadow-sm">
						<label for="telefono" class="sr-only">Teléfono</label>
						<input v-model="state.model.telefono" id="telefono" placeholder="Teléfono" :disabled="state.sending" class="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:accent focus:border-accent focus:z-10 sm:text-sm" />
					</div>
					<div class="text-xs mt-2 ml-1 text-gray-400">Teléfono a 10 números, sin espacios, sin guiones y sin paréntesis.</div>
					<div class="text-xs text-red-500 ml-1 mt-1" v-if="v$.$error">
						<div v-if="v$.model.telefono.$error">{{v$.model.telefono.$errors[0].$message}}</div>
					</div>

					<div class="mt-5">
						<Selectable title="Especialidad que actualmente cursas:" :items="state.especialidades" v-model="state.model.especialidad" />
						<div class="text-xs text-red-500 ml-1 mt-2" v-if="v$.$error">
							<div v-if="v$.model.especialidad.$error">{{v$.model.especialidad.$errors[0].$message}}</div>
						</div>
					</div>

					<div class="mt-5">
						<Selectable title="Grado de residencia:" :items="state.grados" v-model="state.model.grado" />
						<div class="text-xs text-red-500 ml-1 mt-2" v-if="v$.$error">
							<div v-if="v$.model.grado.$error">{{v$.model.grado.$errors[0].$message}}</div>
						</div>
					</div>

					<div class="mt-5">
						<Selectable title="Estado donde actualmente realizas la residencia:" :items="state.estados" v-model="state.model.estadoActual" />
						<div class="text-xs text-red-500 ml-1 mt-2" v-if="v$.$error">
							<div v-if="v$.model.estadoActual.$error">{{v$.model.estadoActual.$errors[0].$message}}</div>
						</div>
					</div>
					
					<div class="mt-5">
						<Selectable title="Estado donde naciste:" :items="state.estados" v-model="state.model.estadoNatal" />
						<div class="text-xs text-red-500 ml-1 mt-2" v-if="v$.$error">
							<div v-if="v$.model.estadoNatal.$error">{{v$.model.estadoNatal.$errors[0].$message}}</div>
						</div>
					</div>
				</div>

				<div>
					<button type="submit" v-if="!state.sending" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary hover:bg-accent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent">
						<span class="absolute left-0 inset-y-0 flex items-center pl-3">
							<LockClosedIcon class="h-5 w-5 text-white group-hover:text-white opacity-50" aria-hidden="true" />
						</span>
						Guardar y continuar
					</button>

					<div v-if="state.sending" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-gray-400 bg-gray-200">
						Espera un momento...
					</div>
				</div>
			</form>

			<FooterPublic class="mt-10" />
		</div>
	</div>
</template>

<script>
import useValidate from '@vuelidate/core'
import { required, minLength, maxLength, helpers, numeric } from '@vuelidate/validators'
import { LockClosedIcon } from '@heroicons/vue/solid'
import {  BellIcon } from '@heroicons/vue/outline'
import { onMounted, computed, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import Sign from '@/firebase/sign'
import Users from '@/firebase/users'
import FooterPublic from '@/components/core/FooterPublic'
import Selectable from '@/components/core/Selectable'
import Misc from '@/services/misc'

export default {
		components: {
			LockClosedIcon,
			FooterPublic,
			Selectable,
			BellIcon
		},
		setup() {
			const store = useStore();
			const route = useRoute();
			const router = useRouter();

			const user = computed(() => store.getters.user);

			onMounted(() => {
				if (user.name) {
					router.push('/');
				}
			});

			const state = reactive({
				model: {
					name: '',
					paterno: '',
					materno: '',
					cedula: '',
					telefono: '',
					institucion: '',
					especialidad: null,
					grado: null,
					estadoActual: null,
					estadoNatal: null
				},
				sending: false,
				especialidades: Misc.especialidades,
				estados: Misc.estados,
				grados: Misc.grados
			});

			const mustBeCool = (value) =>  { return value != null && value.id != null };

			const rules = computed(() => {
				return {
					model: {
						name: { 
							required: helpers.withMessage('El nombre es requerido.', required),
							minLength: helpers.withMessage('Nombre no válido', minLength(3)),
							maxLength: helpers.withMessage('Máximo 40 caracteres', maxLength(40)),
						},
						paterno: { 
							required: helpers.withMessage('El apellido paterno es requerido.', required),
							minLength: helpers.withMessage('Apellido no válido', minLength(3)),
							maxLength: helpers.withMessage('Máximo 40 caracteres', maxLength(40)),
						},
						cedula: { 
							required: helpers.withMessage('La cédula es requerida.', required),
							minLength: helpers.withMessage('Cédula no válida.', minLength(4)),
							maxLength: helpers.withMessage('Cédula no válida.', maxLength(30)),
						},
						telefono: { 
							required: helpers.withMessage('El teléfono es requerido.', required),
							numeric: helpers.withMessage('Teléfono no válido. Escribe sólo números sin espacios.', numeric),
							minLength: helpers.withMessage('Escribe el teléfono a 10 números.', minLength(10)),
							maxLength: helpers.withMessage('Escribe el teléfono a 10 números.', maxLength(10)),
						},
						institucion: { 
							required: helpers.withMessage('La institución requerido.', required),
							minLength: helpers.withMessage('Institución no válida', minLength(2)),
							maxLength: helpers.withMessage('Máximo 60 caracteres', maxLength(40)),
						},
						especialidad: { 
							mustBeCool: helpers.withMessage('Es necesario seleccionar la especialidad.', mustBeCool),
						},
						grado: { 
							mustBeCool: helpers.withMessage('Es necesario seleccionar el grado de residencia.', mustBeCool),
						},
						estadoActual: { 
							mustBeCool: helpers.withMessage('Es necesario seleccionar el estado.', mustBeCool),
						},
						estadoNatal: { 
							mustBeCool: helpers.withMessage('Es necesario seleccionar el estado.', mustBeCool),
						}
					}
				}
			});

			const v$ = useValidate(rules, state);

			function submitForm() {
				v$.value.$validate()

				if (!v$.value.$error) {
					state.sending = true;

					let final = prepare();

					Users.update(user.value.uid, final)
						.then(response => {
							if (response == true) {
								state.sending = false;
								router.push('/pending');
							}
						});
				}
			}

			function prepare() {
				let obj = JSON.parse(JSON.stringify(state.model));

				obj.especialidad = obj.especialidad.id;
				obj.grado = obj.grado.id;
				obj.estadoActual = obj.estadoActual.id;
				obj.estadoNatal = obj.estadoNatal.id;

				return obj;
			}

			function signout() {
				if (!state.sending) {
					Sign.signout()
						.then(response => {
							if (response === true) {
								store.dispatch('setNotification', {type: true, title: 'Hasta luego', content: 'Le esperamos pronto.'});
							} else {
								store.dispatch('setNotification', {type: false, content: 'Hubo un error de conexión.'});
							}
						});
				}
			}

			return {
				signout,
				state,
				submitForm,
				v$
			}
		}
}
</script>